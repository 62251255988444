import { graphql } from "gatsby";
import React, { useState } from "react";
// import Masonry from "react-masonry-css";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TemplateCard from "../components/TemplateCard";
import TemplateFilterButton from "../components/TemplateFilterButton";

const FormList = ({ data }) => {
  const posts = data.allMdx.edges;

  const [showTemplate, setShowTemplate] = useState("all");

  const handleShowTemplate = (templateTag) => {
    setShowTemplate(templateTag);
  };

  // const breakpointColumnsObj = {
  //   default: 3,
  //   1200: 2,
  //   720: 1,
  // };

  return (
    <Layout>
      <Seo
        title="Free HTML Form Templates"
        description="Ready to use free HTML form templates that you can copy-paste and start using immediately with FormBold, we crafted almost all essential forms you may need and available in HTML and tailwind version."
      />

      <div className="bg-[#F5F8FF] pt-32 md:pt-44 pb-16">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div className="max-w-[690px] mx-auto mb-[60px] text-center">
                <h2 className="font-bold text-3xl md:text-4xl xl:text-[40px] leading-tight md:leading-tight xl:leading-tight text-black text-center mb-5">
                  Form Templates
                </h2>
                <p className="font-medium text-base text-body-color leading-relaxed">
                  Ready to use free HTML form templates that you can copy-paste
                  and start using immediately with FormBold, we crafted almost
                  all essential forms you may need and available in HTML and
                  tailwind version.
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-center -mx-4">
            <div className="w-full text-center px-4">
              <div className="overflow-x-auto max-w-full inline-flex items-center rounded-lg shadow-card p-4 space-x-4 mb-12 bg-white">
                <TemplateFilterButton
                  handleShowTemplate={handleShowTemplate}
                  showTemplate={showTemplate}
                  templateTag="all"
                  text="All"
                />
                <TemplateFilterButton
                  handleShowTemplate={handleShowTemplate}
                  showTemplate={showTemplate}
                  templateTag="contact"
                  text="Contact"
                />
                <TemplateFilterButton
                  handleShowTemplate={handleShowTemplate}
                  showTemplate={showTemplate}
                  templateTag="appointment"
                  text="Appointment"
                />
                <TemplateFilterButton
                  handleShowTemplate={handleShowTemplate}
                  showTemplate={showTemplate}
                  templateTag="rsvp"
                  text="RSVP"
                />
                <TemplateFilterButton
                  handleShowTemplate={handleShowTemplate}
                  showTemplate={showTemplate}
                  templateTag="chatbox"
                  text="Chatbox"
                />
                <TemplateFilterButton
                  handleShowTemplate={handleShowTemplate}
                  showTemplate={showTemplate}
                  templateTag="file-upload"
                  text="File Upload"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-4">
            {posts.map((template, index) => (
              <TemplateCard
                template={template}
                key={index}
                showTemplate={showTemplate}
              />
            ))}
          </div>

          {/* <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {posts.map((template, index) => (
              <TemplateCard
                template={template}
                key={index}
                showTemplate={showTemplate}
              />
            ))}
          </Masonry> */}
        </div>
      </div>
    </Layout>
  );
};

export default FormList;

export const pageQuery = graphql`
  query blogPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { frontmatter: { category: { eq: "templates" } } }) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            category
            excerpt
            featured_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            tag
            featured_image_alt
            title
          }
          body
        }
      }
    }
  }
`;
