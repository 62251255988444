import React from "react";

export default function TemplateFilterButton({
  handleShowTemplate,
  showTemplate,
  templateTag,
  text,
}) {
  return (
    <>
      <button
        className={`max-w-full flex items-center border py-3 px-6 rounded-lg font-medium text-lg whitespace-nowrap hover:bg-primary hover:border-primary hover:text-white transition-all ${
          showTemplate === templateTag
            ? "bg-primary text-white border-primary"
            : "bg-[#F5F8FF] text-body-color border-[#E9E9E9]"
        } `}
        onClick={() => handleShowTemplate(templateTag)}
      >
        {text}
      </button>
    </>
  );
}
