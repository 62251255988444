import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

export default function BlogCard({ template, showTemplate, templateTag }) {
  const { slug, frontmatter } = template.node;
  const image = getImage(frontmatter.featured_image);
  return (
    <>
      <div
        className={`w-full md:w-1/2 xl:w-1/3 px-4 ${
          showTemplate === "all" || showTemplate === frontmatter.tag
            ? "block"
            : "hidden"
        } `}
      >
        <article className="bg-white rounded-lg shadow-card mb-10 relative">
          <div className="p-7 border-b border-[#eee]">
            <Link to={`/templates/${slug}`}>
              <GatsbyImage
                image={image}
                alt={frontmatter.featured_image_alt}
                className="rounded w-full"
              />
            </Link>
          </div>

          <div className="p-7">
            <h3 className="font-semibold text-xl text-black hover:text-primary mb-4">
              <Link to={`/templates/${slug}`} className="block">
                {frontmatter.title}
              </Link>
            </h3>
            <p className="font-medium text-base text-body-color leading-relaxed mb-5">
              {frontmatter.excerpt}
            </p>
            <Link
              to={`/templates/${slug}`}
              className="font-semibold text-sm text-white py-3 px-6 bg-primary rounded hover:shadow-lg  transition inline-flex"
            >
              Get the code
            </Link>
          </div>
        </article>
      </div>
    </>
  );
}
